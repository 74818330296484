const URL = 'https://jira.connectwisedev.com';
const PROJECT_ID = 12200;
const COMPONENT_ID = 14526;
const BUG_ISSUE_TYPE = 1;
const PRIORITY = 4;
const DESCRIPTION_FIELD = 'customfield_11505';

export function getIssueUrl(summary, description) {
    const baseIssueUrl = `${URL}/secure/CreateIssueDetails!init.jspa`;
    return `${baseIssueUrl}?pid=${PROJECT_ID}&components=${COMPONENT_ID}&issuetype=${BUG_ISSUE_TYPE}&priority=${PRIORITY}&summary=${encodeURIComponent(summary)}&${DESCRIPTION_FIELD}=${encodeURIComponent(description)}`;
}
