import * as core from 'cw-ui-core';
import { connect } from 'react-redux';

const HasAppRoleAccess = props => {
    const hasAccess = () => {
        if (props.allow) {
            let allowAccess = false;
            for (let role of props.allow) {
                if(props.userAppRoles.appRoleId === undefined){ break; }
                allowAccess = props.userAppRoles.appRoleId.includes(role);
                if (props.requireAll && !allowAccess) { break; } 
                else if (!props.requireAll && allowAccess) { break; }
            }
            return allowAccess;
        }

        return false;
    };

    if (hasAccess()) {
        return props.children;
    }

    return props.fallback ? props.fallback : null;
};

const mapStateToProps = state => {
    const userStoreObject = core.getUser(state.oidc);
    const userAppRoles = userStoreObject.getIn(['profile', 'app_role_assignments']);
    let userAppRolesParsed;
    if (userAppRoles) {
        userAppRolesParsed = typeof userAppRoles === 'string' || userAppRoles instanceof String ? [userAppRoles] : userAppRoles.toJS();
    }
    return {
        userAppRoles: userAppRolesParsed ? userAppRolesParsed : [],
    };
};

export default connect(mapStateToProps)(HasAppRoleAccess);
