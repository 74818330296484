import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import * as core from 'cw-ui-core';
import ErrorBoundary from 'src/components/ErrorPage/ErrorBoundary';
import HasAccess from 'src/components/HasAccess/HasAccess';
import HasAppRoleAccess from '../HasAppRoleAccess/HasAppRoleAccess';
import AccessDeniedPage from 'src/components/AccessDeniedPage/AccessDeniedPage';
import css from './PageBoundary.scss';

const PageBoundary = props => {
    let children = (
        <Fragment>
            {props.header}
            <core.ScreenMessagePanelContainer className={css.screenMessage} screenKey={props.screenKey} />
            {props.children}
        </Fragment>
    );

    if (props.permissions) {
        children = (
            <HasAccess allow={props.permissions} fallback={<AccessDeniedPage />}>
                {children}
            </HasAccess>
        );
    }
    
    if (props.approles) {
        children = (
            <HasAppRoleAccess allow={props.approles} fallback={<AccessDeniedPage />}>
                {children}
            </HasAppRoleAccess>
        );
    }

    if (props.approles) {
        children = (
            <HasAppRoleAccess allow={props.approles} fallback={<AccessDeniedPage />}>
                {children}
            </HasAppRoleAccess>
        );
    }

    return <ErrorBoundary>{children}</ErrorBoundary>;
};

export default injectIntl(PageBoundary);
