export const CONTACT_CLAIMS = {
  contact_id: 'contact_id'
};

export function getUserContactInfoFromClaims(userClaims) {
  if (userClaims === null || userClaims?.length === 0) {
    return {};
  }

  const contactIdClaimValue = findContactClaim(userClaims, CONTACT_CLAIMS.contact_id);

  return {
    contactId: contactIdClaimValue
  };
}

function findContactClaim(userClaims, claimType, type = undefined) {
  const claim = userClaims?.find(
    claim => claim.claimType === claimType && ((type != null && claim.claimValue.type === type) || type == null)
  );
  return claim?.claimValue?.value ?? claim?.claimValue ?? '';
}