import React, { useEffect } from 'react';
import * as core from 'cw-ui-core';
import { injectIntl } from 'react-intl';
import Row from 'src/components/Shared/Row';
import Column from 'src/components/Shared/Column';
import PageBoundary from 'src/components/PageBoundary/PageBoundary';
import css from 'src/components/Partner/AddEntitlementDialog.scss';
import LoadingComponent from '../Loading/LoadingComponent';

export const SWAP_ENTITLEMENT_DIALOG_KEY = 'swap_partner_entitlement_dialog';
export const FORM_FIELDS = {
    OLD_ENTITLEMENT: 'swap_entitlement_dialog_old_entitlement',
    UPDATED_ENTITLEMENT: 'swap_entitlement_dialog_updated_entitlement',
    ASSIGN_TO_PARTNERID: 'add_entitlement_dialog_assign_to_partnerid',
    ASSIGN_TO_MEMBERID: 'add_entitlement_dialog_assign_to_memberid',
};

const SwapEntitlementDialog = props => {
    const { onWillMount } = props;

    useEffect(() => {
        onWillMount();
    }, [onWillMount]);

    return (
        <core.Dialog className={css.dialog}>
            {props.isBusy ? (
                <LoadingComponent />
            ) : (
                <PageBoundary
                    screenKey={SWAP_ENTITLEMENT_DIALOG_KEY}
                    header={<core.Label className={css.title} value="Swap Partner Entitlement" />}>
                    <Row vertical className={css.row}>
                        <Column className={css.formLabel}>Old Entitlement:</Column>
                        <Column>
                            <core.FormFieldContainer
                                path={[props.oldEntitlementName]}
                                style={{width: 350}}>
                                <core.TextField
                                    className={css.whiteBackground}
                                    readOnly={true}
                                    value={props.oldEntitlementName}
                                />
                            </core.FormFieldContainer>
                        </Column>
                    </Row>
                    <Row vertical className={css.row}>
                        <Column className={css.formLabel}>Updated Entitlement:</Column>
                        <Column>
                            <core.FormFieldContainer path={[FORM_FIELDS.UPDATED_ENTITLEMENT]}>
                                <core.ComboBox expandOnClick items={props.availableEntitlements} className={css.multiSelectCombo} filterOnPartialMatch />
                            </core.FormFieldContainer>
                        </Column>
                    </Row>
                    <Row vertical className={css.row}>
                        <Column className={css.formLabel}>Assign To:</Column>
                        <Column>
                            <core.FormFieldContainer path={[FORM_FIELDS.ASSIGN_TO_PARTNERID]} className={css.formField}>
                                <core.CheckBox text="Partner ID" />
                            </core.FormFieldContainer>
                            <core.FormFieldContainer path={[FORM_FIELDS.ASSIGN_TO_MEMBERID]} className={css.formField}>
                                <core.CheckBox text="Legacy Member ID" />
                            </core.FormFieldContainer>
                        </Column>
                    </Row>
                    <div className={css.buttonContainer}>
                        <core.ButtonStandard
                            automationName="swap_entitlement_dialog_complete_button"
                            value="Swap"
                            onClick={props.handleSwapClick}
                        />
                        <core.ButtonStandard
                            automationName="swap_entitlement_dialog_cancel_button"
                            value="Cancel"
                            onClick={props.handleCancelClick}
                        />
                    </div>
                </PageBoundary>
            )}
        </core.Dialog>
    );
};

export default injectIntl(SwapEntitlementDialog);
