import React, { useEffect } from 'react';
import * as core from 'cw-ui-core';
import { injectIntl } from 'react-intl';
import Row from 'src/components/Shared/Row';
import Column from 'src/components/Shared/Column';
import PageBoundary from 'src/components/PageBoundary/PageBoundary';
import css from 'src/components/Partner/AddEntitlementDialog.scss';
import LoadingComponent from '../Loading/LoadingComponent';

export const ADD_ENTITLEMENT_DIALOG_KEY = 'add_entitlement_to_partner_dialog';
export const FORM_FIELDS = {
    ENTITLEMENT_TO_ADD: 'add_entitlement_dialog_entitlement_to_add',
    ASSIGN_TO_PARTNERID: 'add_entitlement_dialog_assign_to_partnerid',
    ASSIGN_TO_MEMBERID: 'add_entitlement_dialog_assign_to_memberid',
};

const AddEntitlementDialog = props => {
    const { onWillMount } = props;

    useEffect(() => {
        onWillMount();
    }, [onWillMount]);

    return (
        <core.Dialog className={css.dialog}>
            {props.isBusy ? (
                <LoadingComponent />
            ) : (
                <PageBoundary
                    screenKey={ADD_ENTITLEMENT_DIALOG_KEY}
                    header={<core.Label className={css.title} value="Add Entitlement to Partner" />}>
                    <Row vertical className={css.row}>
                        <Column className={css.formLabel}>Entitlement:</Column>
                        <Column>
                            <core.FormFieldContainer path={[FORM_FIELDS.ENTITLEMENT_TO_ADD]}>
                                <core.MultiSelectComboBox items={props.availableEntitlements} filterOnPartialMatch className={css.multiSelectCombo}/>
                            </core.FormFieldContainer>
                        </Column>
                    </Row>
                    <Row vertical className={css.row}>
                        <Column className={css.formLabel}>Assign To:</Column>
                        <Column>
                            <core.FormFieldContainer path={[FORM_FIELDS.ASSIGN_TO_PARTNERID]} className={css.formField}>
                                <core.CheckBox text="Partner ID" />
                            </core.FormFieldContainer>
                            <core.FormFieldContainer path={[FORM_FIELDS.ASSIGN_TO_MEMBERID]} className={css.formField}>
                                <core.CheckBox text="Legacy Member ID" />
                            </core.FormFieldContainer>
                        </Column>
                    </Row>
                    <div className={css.buttonContainer}>
                        <core.ButtonStandard
                            automationName="add_entitlement_dialog_add_button"
                            value="Add"
                            onClick={props.handleAddClick}
                        />
                        <core.ButtonStandard
                            automationName="add_entitlement_dialog_cancel_button"
                            value="Cancel"
                            onClick={props.handleCancelClick}
                        />
                    </div>
                </PageBoundary>
            )}
        </core.Dialog>
    );
};

export default injectIntl(AddEntitlementDialog);
