import { createBrowserHistory } from 'history';

let history;

export const getHistory = () => {
    if (!history) {
        /**
         * https://stackoverflow.com/questions/42672842/how-to-get-history-on-react-router-v4
         * https://stackoverflow.com/questions/44153517/how-to-access-history-object-outside-of-a-react-component
         * https://stackoverflow.com/questions/42701129/how-to-push-to-history-in-react-router-v4/42716055
         */
        history = createBrowserHistory();
    }

    return history;
};

export default getHistory;
