import React from 'react';
import { injectIntl } from 'react-intl';
import * as core from 'cw-ui-core';
import { getIssueUrl } from 'src/services/JiraService';
import Locale from 'src/Locale';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            info: null,
        };
    }

    componentDidCatch(error, info) {
        this.setState({
            hasError: true,
            error: error,
            info: info,
        });
        //TODO: Log to error service
    }

    onLinkClicked = () => {
        const currentUrl = window.location;
        const { error } = this.state;
        const minifiedError = error.message.includes('Minified React error');
        const summary = `PI Error: ${minifiedError ? '' : error.message.substring(0, 100)}`;
        const description = `Url where issue occurred: ${currentUrl}\r\nError: {noformat}${error.message}{noformat}\r\nReproduce Steps / more info: \r\nScreenshot: `;
        const issueUrl = getIssueUrl(summary, description);
        window.open(issueUrl, '_blank');
    };

    render() {
        const { formatMessage } = this.props.intl;
        const message = (
            <span>
                {formatMessage(Locale.something_went_wrong)}{' '}
                <core.Label
                    isLink={true}
                    onClick={this.onLinkClicked}
                    value={formatMessage(Locale.contact_app_services)}
                />
            </span>
        );
        if (this.state.hasError) {
            return (
                <core.Notification
                    message={message}
                    status="Error"
                    statusIcon={core.IconPath.get('Status_Warning')}
                    onClose={() => null}
                    closeIcon={''}
                />
            );
        }
        return this.props.children;
    }
}

export default injectIntl(ErrorBoundary);
