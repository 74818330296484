import React from 'react';
import { connect } from 'react-redux';
import * as core from 'cw-ui-core';
import Locale from 'src/Locale';
import { injectIntl } from 'react-intl';
import ErrorPage from '../ErrorPage/ErrorPage';
import history from 'src/services/History';

const AccessDeniedPage = props => {
    const { formatMessage } = props.intl;

    return (
        <ErrorPage
            imagePath={'/images/accessdenied-image.png'}
            title={formatMessage(Locale.access_denied_title)}
            subtitle={formatMessage(Locale.access_denied_subtitle)}
            button={
                <core.ButtonStandard
                    automationName={'next'}
                    value={formatMessage(Locale.take_me_home)}
                    onClick={props.onClickHomePage}
                />
            }
        />
    );
};

const mapDispatchToProps = () => {
    return {
        onClickHomePage: () => {
            history().push('');
        },
    };
};

export default injectIntl(connect(undefined, mapDispatchToProps)(AccessDeniedPage));
