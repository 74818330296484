import React, { useEffect } from 'react';
import * as core from 'cw-ui-core';
import css from './ErrorPage.scss';

const ErrorPage = ({ imagePath, title, subtitle, button, onWillMount, children }) => {
    useEffect(() => {
        if (onWillMount) {
            onWillMount();
        }
    }, [onWillMount]);

    return (
        <div className={css.content}>
            <core.Image className={css.image} src={imagePath} isInlineSVG={false} />
            <core.Label className={css.title} value={title} />
            <core.Label className={css.subtitle} grey2 value={subtitle} />
            {button ? <div className={css.btn}>{button}</div> : ''}
            {children}
        </div>
    );
};

export default ErrorPage;
