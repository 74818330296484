if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable();
  window.Promise = require('promise/lib/es6-extensions.js');
}

// fetch() polyfill for making API calls.
require('whatwg-fetch');

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign');

//common utility functions
require('core-js/fn/array/includes');
require('core-js/fn/array/find');
require('core-js/fn/array/find-index');
require('core-js/fn/string/includes');
require('core-js/fn/string/starts-with');
require('core-js/fn/string/ends-with');

if (!global.Intl || navigator.userAgent.toLowerCase().indexOf('trident') > -1 || navigator.userAgent.toLowerCase().indexOf("edge") > -1) {
	global.Intl = require('intl');
}