import { connect } from 'react-redux';
import * as core from 'cw-ui-core';
import LandingPage from './LandingPage';

const mapStateToProps = state => {
    let configData = core.getModuleCache(state.module, ['portal_configuration']);
    const checkIsReady = core.getModuleCache(state.module, ['portal_configuration']);

    if(!configData){
        configData={};
        configData.featureImageUrl = "/images/feature-image.jpg";
        configData.titleText = "Product Insights";
       }

     return {

        notfound: core.getScreenCustom(state.screen, ['notfound']),
        isReady: checkIsReady ? true : false, // only render when we have config data
        featureImageUrl:  configData.featureImageUrl,
        logoUrl: core.getModuleCache(state.module, ['portal_configuration', 'logoUrl']),
        titleText: configData.titleText,
        titleSubtext: core.getModuleCache(state.module, ['portal_configuration', 'titleSubtext'])
    };
};

const mapDispatchToProps = () => {
    return {};
};

const LandingPageContainer = connect(mapStateToProps, mapDispatchToProps)(LandingPage);

export default LandingPageContainer;
