import React from 'react';
import * as core from 'cw-ui-core';
import Locale from 'src/Locale';
import ErrorPage from '../ErrorPage/ErrorPage';

const SignInErrorPage = props => {
    const onErrorRetryClick = () => {
        window.location.href = '/';
    };

    const { oidcError } = props;

    let errorMessage, errorTitle, errorButtonText;
    if (oidcError) {
        errorMessage = oidcError.message;
        errorTitle = core.formatMessage(Locale.error_signing_in); //must use core.formatMessage because this page may appear without IntlProvider wrapper
        errorButtonText = core.formatMessage(Locale.try_again);
    }

    return (
        <ErrorPage
            imagePath={'/images/accessdenied-image.png'}
            title={errorTitle}
            subtitle={errorMessage}
            button={
                <core.ButtonStandard
                    automationName={'retry_load'}
                    value={errorButtonText}
                    onClick={onErrorRetryClick}
                />
            }
        />
    );
};

export default SignInErrorPage;
