import React from 'react';
import * as core from 'cw-ui-core';
import Locale from 'src/Locale';
import { connect } from 'react-redux';
import ErrorPage from '../ErrorPage/ErrorPage';
import history from 'src/services/History';

const NotFoundPage = props => {
    return (
        <ErrorPage
            imagePath={'/images/notfound-image.png'}
            title={props.title || core.formatMessage(Locale.cant_find_page)} //must use core.formatMessage because this page may appear without IntlProvider wrapper
            subtitle={props.subtitle || core.formatMessage(Locale.check_url)}
            onWillMount={props.onWillMount}
            button={
                props.includeHome ? (
                    <core.ButtonStandard
                        automationName={'take_me_home'}
                        value={props.btnText}
                        onClick={props.onClickHomePage}
                    />
                ) : null
            }
        />
    );
};

const mapDispatchToProps = () => {
    return {
        onWillMount: () => {
            core.runSaga(core.addNavigation, 'not_found_page', window.location.href); // log error page navigation
        },
        onClickHomePage: () => {
            history().push('');
        },
    };
};

export default connect(undefined, mapDispatchToProps)(NotFoundPage);
