import React from 'react';
import * as core from 'cw-ui-core';
import { ADD_ENTITLEMENT_DIALOG_KEY } from 'src/components/Partner/AddEntitlementDialog';
import { SWAP_ENTITLEMENT_DIALOG_KEY } from 'src/components/Partner/SwapEntitlementDialog';

const componentLoader = path => async callback => callback(await import('./' + path));

const getDialog = (dialogID, props = null) => {
    switch (dialogID) {
        case 'confirm_dialog':
            return (
                <core.AsyncComponent
                    key={dialogID}
                    {...props}
                    loader={componentLoader('components/ConfirmDialog/ConfirmDialogContainer')}
                />
            );
        case 'confirm_dialog_swapped_btn_style':
            return (
                <core.AsyncComponent
                    key={dialogID}
                    {...props}
                    loader={componentLoader('components/ConfirmDialog/ConfirmDialogSwappedBtnStyleContainer')}
                />
            );
        case 'add_product_dialog':
            return (
                <core.AsyncComponent
                    key={dialogID}
                    {...props}
                    loader={componentLoader('components/Product/AddProductContainer')}
                />
            );
        case 'edit_credit_card_dialog':
            return (
                <core.AsyncComponent
                    key={dialogID}
                    {...props}
                    loader={componentLoader('components/CreditCard/EditCreditCardContainer')}
                />
            );
        case 'product_instance_details_dialog':
            return (
                <core.AsyncComponent
                    key={dialogID}
                    {...props}
                    loader={componentLoader('components/ProductInstance/ProductInstanceDetailsDialogContainer')}
                />
            );
        case 'remote_support_dialog':
            return (
                <core.AsyncComponent
                    key={dialogID}
                    {...props}
                    loader={componentLoader('components/Support/RemoteSupportDialogContainer')}
                />
            );
        case 'reassign_user_dialog':
            return (
                <core.AsyncComponent
                    key={dialogID}
                    {...props}
                    loader={componentLoader('components/User/ReassignUserDialogContainer')}
                />
            );
        case 'reassign_product_instance_dialog':
            return (
                <core.AsyncComponent
                    key={dialogID}
                    {...props}
                    loader={componentLoader('components/ProductInstance/ReassignProductInstanceDialogContainer')}
                />
            );
        case 'reassign_client_dialog':
            return (
                <core.AsyncComponent
                    key={dialogID}
                    {...props}
                    loader={componentLoader('components/Client/ReassignClientDialogContainer')}
                />
            );
        case 'client_detail_assign_aditional_user':
            return (
                <core.AsyncComponent
                    key={dialogID}
                    {...props}
                    loader={componentLoader('components/Client/AssignAdditionalUserDialogContainer')}
                />
            );
        case ADD_ENTITLEMENT_DIALOG_KEY:
            return (
                <core.AsyncComponent
                    key={dialogID}
                    {...props}
                    loader={componentLoader('components/Partner/AddEntitlementDialogContainer')}
                />
            );
        case SWAP_ENTITLEMENT_DIALOG_KEY:
            return (
                <core.AsyncComponent
                    key={dialogID}
                    {...props}
                    loader={componentLoader('components/Partner/SwapEntitlementDialogContainer')}
                />
            );
        case 'add_package_dialog':
            return (
                <core.AsyncComponent
                    key={dialogID}
                    {...props}
                    loader={componentLoader('components/Product/Package/AddPackageContainer')}
                />
            );
        case 'on_create_product':
            return (
                <core.AsyncComponent
                    key={dialogID}
                    {...props}
                    loader={componentLoader('components/Product/Entitlements/FormPopup/CreateProductContainer')}
                />
            );
        case 'on_create_feature':
            return (
                <core.AsyncComponent
                    key={dialogID}
                    {...props}
                    loader={componentLoader('components/Product/Entitlements/FormPopup/CreateFeatureContainer')}
                />
            );
        case 'on_map_feature_to_product':
            return (
                <core.AsyncComponent
                    key={dialogID}
                    {...props}
                    loader={componentLoader('components/Product/Entitlements/FormPopup/MapFeatureToProductContainer')}
                />
            );
        case 'on_update_product':
            return (
                <core.AsyncComponent
                    key={dialogID}
                    {...props}
                    loader={componentLoader('components/Product/Entitlements/FormPopup/EditProductContainer')}
                />
            );
        default:
            return core.getCoreDialog(dialogID, props);
    }
};

export default getDialog;
