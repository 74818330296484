import React from 'react';
import * as core from 'cw-ui-core';

const defaultGutter = '20px';

const Column = (props) => (
  <core.Flexbox
    className={props.className}
    style={props.gutter ? Object.assign({}, props.style, {marginRight: props.gutter}) : {marginRight: defaultGutter}}
    verticalAlign={props.center || props.vertical}
    horizontalAlign={props.center || props.horizontal}
    column>
    {props.children}
  </core.Flexbox>
);

export default Column;