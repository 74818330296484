import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as core from 'cw-ui-core';
import App from './App';
import { GET } from 'src/services/ApiService';

async function onWillMount(callback) {
    const build = core.getModuleConfig(core.Store().getState().module, ['build']);

    if(build === 'dev') {
        window.setCurrentPage = (id, page) => {
            const total = core.getModuleCache(core.Store().getState().module, [id, 'pager', 'totalPages']);
            if(page >= 1 && page <= parseInt(total)) {
                core.Store().dispatch(core.setModuleCache([id, 'pager', 'currentPage'], page));
            }
        };
    }

    const [integrations] = await Promise.all([GET(`/config/integrations`)]);

    if (!integrations.isSuccess) {
        callback(false);
        return;
    }

    core.Store().dispatch(core.setModuleConfig(['integrations'], integrations.response));
    callback(true);
}

const mapDispatchToProps = () => {
    return {
        onWillMount: callback => {
            onWillMount(callback);
        },
        onMenuItemClick: () => {},
    };
};

export default withRouter(connect(undefined, mapDispatchToProps)(App));
