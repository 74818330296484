import React from 'react';
import * as core from 'cw-ui-core';
import getDialog from './DialogFactory';
import AppContainer from 'src/components/App/AppContainer';
import { BrowserRouter as Router } from 'react-router-dom';
import LandingPageContainer from 'src/components/LandingPage/LandingPageContainer';
import { init } from 'src/services/AuthService';
import SignInErrorPage from 'src/components/SignInErrorPage/SignInErrorPage';

export const MODULE_ID = 'product-insights';
core.setTheme('#0067B1');

const onBeforeAppLoad = async () => {
    init();
};

const config = {
    onBeforeAppLoad,
    oidcLoginComponent: <LandingPageContainer />,
    oidcErrorComponent: SignInErrorPage,
    includeOidc: true,
    screenComponent: (
        <Router>
            <AppContainer />
        </Router>
    ),
    getDialog,
};

core.appEntryPoint(MODULE_ID, config);
