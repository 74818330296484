import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Locale from 'src/Locale';
import UserAccount from 'src/components/App/UserAccount';
import { loadColleaguePiRoles } from '../../services/UserService';
import * as core from 'cw-ui-core';

const UserAccountPi = props => {
    const { formatMessage } = props.intl;

    return (
        <UserAccount
            initials={props.initials}
            userName = {props.userName}
            email = {props.email}
            items={[{ id: 'logout', name: formatMessage(Locale.logout) }]}
            onMenuItemClick={props.onUserAccountItemClicked}
            userAppRoles = {props.userAppRolesParsed}
            userFilteredIdentityRoles = {props.filteredRoles}
        />
    );
};

const processIdentityRoles = state => {
    //TODO: Remove this section once the IAM team completes and releases IAM-11188
    let filteredOutputRoles = [];
    const roles = core.getUserRoles(state.oidc);
    let userRolesParsed;
    if (roles) {
        userRolesParsed = typeof roles === 'string' || roles instanceof String ? [roles] : roles.toJS();
    }
    for (const role of userRolesParsed){
        if((role === "MP Admin") || (role === "MP Support Elevated")){
            filteredOutputRoles.push(role);
        }
    }

    //Get the identity roles that have PI App role permissions
    const colleagueUserId = core.getUserId(state.oidc);
    const partnerId = core.getPartnerId(state.oidc);

    let output = "Processing your identity roles...";
    if(core.getModuleCache(core.Store().getState().module, ['matchingPiRoles']) === undefined){
        loadColleaguePiRoles(partnerId, colleagueUserId, filteredOutputRoles).then((roleResults) => {output = roleResults;});
    }
    else{
        output = core.getModuleCache(core.Store().getState().module, ['matchingPiRoles']);
    }

    return output;
};

const mapStateToProps = state => {
    const userName = core.getUserFirstName(state.oidc) + ' ' + core.getUserLastName(state.oidc);
    const email = core.getUserEmail(state.oidc);
    const name = core.getUserName(state.oidc);

    //Identity Roles
    let filteredRoles = "";
    filteredRoles = processIdentityRoles(state);
    if(filteredRoles === ""){
        filteredRoles = "No relevant identity roles assigned";
    }

    //App Roles
    const userAppRoles = core.getUser(state.oidc).getIn(['profile', 'app_role_assignments']);
    let userAppRolesList;
    if (userAppRoles) {
        userAppRolesList = typeof userAppRoles === 'string' || userAppRoles instanceof String ? [userAppRoles] : userAppRoles.toJS();
    }
    let userAppRolesParsed = typeof(userAppRolesList.appRoleId) === "object" ? userAppRolesList.appRoleId.join(",  ") : userAppRolesList.appRoleId;

    //Return data
    return {
        initials: core.getInitials(name),
        userName,
        email,
        userAppRolesParsed,
        filteredRoles,
    };
};

const mapDispatchToProps = () => {
    return {
        onWillMount: async () => {
            await processIdentityRoles();
        },
        onUserAccountItemClicked: id => {
            switch (id) {
                case 'logout':
                    core.signoutRedirect();
                    break;

                default:
                    break;
            }
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UserAccountPi));