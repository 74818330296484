import * as core from 'cw-ui-core';
import { connect } from 'react-redux';

export const MODULES = {
    TRANSACTION: 'Transaction',
    PARTNER: 'Partner',
    PRODUCT: 'Product',
    BILLING: 'Billing',
    PRODUCT_INSTANCE: 'ProductInstance',
    SETTINGS: 'Settings',
    USER: 'User',
    REMOTE: 'Remote',
    PROVISION_ASIO: 'ProvisionAsio'
};

export const ACTIONS = {
    READ: 'read',
    EDIT: 'edit',
    ADD: 'add',
    DELETE: 'delete',
};

const HasAccess = props => {
    const hasAccess = () => {
        if (props.allow) {
            for (let index in props.allow) {
                if (
                    props.allow[index][0] in props.permissions &&
                    props.permissions[props.allow[index][0]][props.allow[index][1]]
                ) {
                    return true;
                }
            }
        }

        return false;
    };

    if (hasAccess()) {
        return props.children;
    }

    return props.fallback ? props.fallback : null;
};

const mapStateToProps = state => {
    const config = core.getModuleConfig(state.module);
    const permissions = core.getModuleCache(state.module, ['permissions']);
    return {
        permissions: permissions ? permissions.toJS() : {},
        env: config.get('build') || 'prod'
    };
};

export default connect(mapStateToProps)(HasAccess);
