import React from 'react';
import PropTypes from 'prop-types';
//@ts-ignore
import css from './UserAccount.scss';
import cx from 'classnames';

class UserAccount extends React.Component {
  constructor(props) {
    super(props);

    this.automationName = 'navmenu_user_account';

    this.state = {
      showMenu: false
    };

    this.userContainerRef = React.createRef();
  }

  onClick = () => {
    this.setState({
      showMenu: true
    });
  };

  onBlur = (event) => {
    const isTargetNotInRef =
      this.userContainerRef &&
      !this.userContainerRef.current.contains(event.target);
    if (
      (event.type === "keyup" && event.key === "Tab" && isTargetNotInRef) ||
      isTargetNotInRef
    ) {
      this.state.showMenu &&
        this.setState({
          showMenu: false,
        });
    }
  };

  onMenuItemClick = (id) => {

    this.setState({
      showMenu: false,
    });
    this.props.onMenuItemClick(id);
  };

  handleKeyDown = (e) => {
    (e.key === " " || e.key === "Enter") && this.onClick();
  };

  handleMenuItemKeyDown = (e, id) => {
    (e.key === " " || e.key === "Enter") && this.onMenuItemClick(id);
  };

  componentDidMount() {
    document.addEventListener("click", this.onBlur);
    document.addEventListener("keyup", this.onBlur);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onBlur);
    document.removeEventListener("keyup", this.onBlur);
  }

  render() {
    return (
      <div
        data-cwid={this.automationName}
        className={cx(this.props.className, css.wrap)}
        tabIndex={-1}
        ref={this.userContainerRef}
      >
        <div
          className={cx("CwThemeBackgroundColor", css.initials)}
          onClick={this.onClick}
          tabIndex={0}
          onKeyDown={this.handleKeyDown}
        >
          {this.props.initials}
        </div>
        {this.state.showMenu ? (
          <div className={css.menu}>
            <div className={css.colleagueName}>{this.props.userName}</div>
            <div className={css.email}>{this.props.email}</div>
            <br />
            <div className={css.profileLabel}>My Roles</div>
            <div className={css.roles}>{this.props.userAppRoles}</div>
            <br />
            <div className={css.profileLabel}>Relevant Identity Roles</div>
            <div className={css.roles}>{this.props.userFilteredIdentityRoles}</div>
            <br />
            {this.props.items.map((item) => {
              return (
                <div
                  key={item.id}
                  className={css.menuItem}
                  onClick={() => this.onMenuItemClick(item.id)}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    this.handleMenuItemKeyDown(e, item.id);
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
}

UserAccount.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  userName: PropTypes.string,
  email: PropTypes.string,
  initials: PropTypes.string,
  userAppRoles: PropTypes.string,
  userFilteredIdentityRoles: PropTypes.string,
  onMenuItemClick: PropTypes.func,
  className: PropTypes.string
};

export default UserAccount;