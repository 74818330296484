import React from 'react';
import * as core from 'cw-ui-core';

const Row = (props) => (
  <core.Flexbox
    className={props.className}
    style={props.style}
    align={'left'}
    verticalAlign={props.center || props.vertical}
    horizontalAlign={true}>
    {props.children}
  </core.Flexbox>
);
export default Row;