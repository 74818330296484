import React from 'react';
import * as core from 'cw-ui-core';
import css from './LandingPage.scss';
import Background from 'src/components/Templates/Background';
import NotFoundPage from 'src/components/NotFoundPage/NotFoundPage';

const LandingPage = props => {
    const { notFound, isReady } = props;

    if (notFound) {
        return <NotFoundPage />;
    }

    if (isReady) {
        return null;
    }

    return (
        <Background>
            <div className={css.content}>
                <core.Image
                    className={css.featureImage}
                    src={props.featureImageUrl}
                    isInlineSVG={false}
                />
                <div className={css.innerContent}>
                    <core.Image className={css.logo} src={props.logoUrl} isInlineSVG={false} />
                    <core.Label className={css.title} value={props.titleText} />
                    <core.Label className={css.subtitle} grey2={true} value={props.titleSubtext} />
                    <core.ButtonStandard
                        automationName={'signin'}
                        isSmall={props.isMobileLayout}
                        isLarge={!props.isMobileLayout}
                        className={css.btn}
                        value={'Sign in'}
                        onClick={props.onSignInClicked}
                    />
                </div>
            </div>
        </Background>
    );
};

export default core.MobileLayoutHOC(LandingPage); // cannot use localization for landing page, which is why the default error page text does not use injectIntl
