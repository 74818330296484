import React from 'react';
import { injectIntl } from 'react-intl';
import css from './LoadingComponent.scss';

const LoadingComponent = () => {
    return (
        <div>
            <div className={css.ldsroller}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default injectIntl(LoadingComponent);
