import React, { useState, useEffect } from 'react';
import * as core from 'cw-ui-core';
import Routes from 'src/services/Routes';
import { Router } from 'react-router-dom';
import UserAccountContainer from './UserAccountContainer';
import AccessDeniedPage from 'src/components/AccessDeniedPage/AccessDeniedPage';
import LoadingComponent from 'src/components/Loading/LoadingComponent';
import history from 'src/services/History';
import './App.scss';

const APP_STATE = {
    LOADING: 'LOADING',
    ACCESS_DENIED: 'ACCESS_DENIED',
    READY: 'READY',
};

const App = props => {
    const [appState, setAppState] = useState(APP_STATE.LOADING);

    const { onWillMount } = props;
    useEffect(() => {
        onWillMount(isAuthorized => {
            setAppState(isAuthorized ? APP_STATE.READY : APP_STATE.ACCESS_DENIED);
        });
    }, [onWillMount]);

    if (appState === APP_STATE.LOADING) {
        return <LoadingComponent />;
    }

    if (appState === APP_STATE.ACCESS_DENIED) {
        return <AccessDeniedPage />;
    }

    const routes = Object.values(Routes);

    return (
        <Router history={history()}>
            <core.NavigationAppContainer
                routes={routes}
                onNavigation={props.onNavigation}
                menu={
                    <core.NavigationMenu
                        routes={routes}
                        logo={core.IconPath.get('Connectwise-icon-LightBlue')}
                        //logoIsInlineSVG={true}
                        footer={<UserAccountContainer />}
                        onMenuItemClick={props.onMenuItemClick}
                    />
                }
            />
        </Router>
    );
};

export default App;
