import { Map } from 'immutable'; // eslint-disable-line
import * as core from 'cw-ui-core';

const IGNORE_UPDATE_FIELDS = ['created', 'updated'];

const stringifyJSON = (data, defaultValue = {}) => {
    try {
        return JSON.stringify(data);
    } catch {
        return JSON.stringify(defaultValue);
    }
};

export const getUrl = endpoint => {
    const config = core.getModuleConfig(core.Store().getState().module);
    const apiUrl = config.get('apiUrl');

    return apiUrl + (endpoint || '');
};

const getHeaders = () => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    //placeholder for additional headers
    return headers;
};

/**
 * Decorated result body of a fetch response, from FetchService.js
 * @property {string} url
 * @property {number} status
 * @property {any} response
 * @property {string} error
 * @property {string[]} errorMessages
 */
export class apiFetchResult {
    url;
    status;
    response;
    error;
    errorMessages;
}

/** @returns {Promise<apiFetchResult>} */
export function GET(endpoint) {
    const options = {
        method: 'GET',
        credentials: 'include',
        headers: getHeaders(),
    };

    return core.apiFetch(getUrl(endpoint), options);
}

/** @returns {Promise<apiFetchResult>} */
export const GETFILEDOWNLOAD = endpoint => {
    const options = {
        method: 'GET',
        credentials: 'include',
        headers: getHeaders(false, false),
    };

    return core.apiFetch(getUrl(endpoint), options, false);
};

/** @returns {Promise<apiFetchResult>} */
export const POST = (endpoint, body) => {
    const options = {
        method: 'POST',
        credentials: 'include',
        headers: getHeaders(),
        body: stringifyJSON(body),
    };

    return core.apiFetch(getUrl(endpoint), options);
};

/** @returns {Promise<apiFetchResult>} */
export const PUT = (endpoint, body) => {
    const options = {
        method: 'PUT',
        credentials: 'include',
        headers: getHeaders(),
        body: stringifyJSON(body),
    };

    return core.apiFetch(getUrl(endpoint), options);
};

/** @returns {Promise<apiFetchResult>} */
export const POSTFORMDATA = (endpoint, formData) => {
    const options = {
        method: 'POST',
        credentials: 'include',
        headers: getHeaders(true, false),
        body: formData,
    };

    return core.apiFetch(getUrl(endpoint), options);
};

const buildPatchPayload = (updates, key, value) => {
    if (Map.isMap(value)) {
        for (let [innerKey, innerValue] of value) {
            if (IGNORE_UPDATE_FIELDS.includes(innerKey)) {
                continue;
            }
            const newKey = key ? `${key}/${innerKey}` : innerKey;
            buildPatchPayload(updates, newKey, innerValue);
        }
    } else {
        updates.push({
            op: 'replace',
            path: `/${key}`,
            value: value,
        });
    }
};

/** @returns {Promise<apiFetchResult>} */
export const PATCH = (endpoint, data) => {
    const patches = [];

    // convert map of data to array of patch entries
    buildPatchPayload(patches, '', data);

    const options = {
        method: 'PATCH',
        credentials: 'include',
        headers: getHeaders(),
        body: stringifyJSON(patches, []),
    };

    return core.apiFetch(getUrl(endpoint), options);
};

/** @returns {Promise<apiFetchResult>} */
export const DELETE = endpoint => {
    const options = {
        method: 'DELETE',
        credentials: 'include',
        headers: getHeaders(),
    };

    return core.apiFetch(getUrl(endpoint), options);
};
